import HelloWorld from '@/component/hello-world'

export default {
  components: {
    HelloWorld,
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    // const __this = this;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
